<template>
    <div
            class="pull-refresh"
            ref="scrollBox"
            :style="style"
            @touchstart="touchStart"
            @touchmove="touchMove"
            @touchend="touchEnd">
        <div class="pull-refreshing-box">
            <div v-if="moveState < 2">{{ moveState === 0 ? '下拉即可刷新...' : '释放即可刷新...' }}</div>
            <div v-else><i class="loading"/> 加载中...</div>
        </div>
        <slot></slot>
    </div>
</template>

<script>
  let timer = null
	import {mapState} from 'vuex'
	export default({
		name: 'vue-load',
		data () {
			return {
				startY: '',
				moveDistance: 0,
				moveState: 0,
				duration: 0,
				move:0
			}
		},

		computed: {
			...mapState([
				'boss',
			]),
			style () {
				return {
					transition: `${this.duration}ms`,
					transform: `translate3d(0,${this.moveDistance}px, 0)`
				}
			}
		},
		methods: {
			touchStart (e) {
				this.duration = 0
				this.moveDistance = 0
				this.startY = e.targetTouches[0].clientY
			},
			touchMove (e) {
				let scrollTop = document.documentElement.scrollTop || document.body.scrollTop
				if (scrollTop > 0) {
					return
				}
				let move = e.targetTouches[0].clientY - this.startY
				let {boss:{allowPull}} = this
        console.log(allowPull)
				if (allowPull&&move > 0&&e.cancelable) {
					this.moveDistance = Math.pow(move, 0.8)
					e.preventDefault()
					if (this.moveDistance > 50&&allowPull) {
						if (this.moveState === 1) {
							return
						}
						this.moveState = 1
					} else {
						if (this.moveState === 0) {
							return
						}
						this.moveState = 0
					}
				}else{
					this.moveDistance = 0
                }
			},
			touchEnd (e) {
				this.duration = 300
				let {boss:{allowPull},moveDistance} = this
        
				if (moveDistance > 50&&allowPull) {
					this.moveState = 2
					this.moveDistance = 50
					this.$emit('loadData')
					setTimeout(()=>{
						this.moveState = 0
					},500)
				} else {
					this.moveDistance = 0
				}
			}
		},
		watch: {
			moveState (state) {
				if (state === 0 && this.duration === 300) {
					this.moveDistance = 0
				}
			}
		},
	})
</script>
<style lang="scss" type="text/scss" rel="stylesheet/scss" scoped>
    .pull-refresh {
        overflow-y:auto;
        margin-top:-50px;
        .pull-refreshing-box {
            line-height: 50px;
            height: 50px;
            font-size: 26px;
            color: rgba(69,90,100,.6);
            text-align: center;
        }
    }
</style>
